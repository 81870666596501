<template>
<v-container>
  <div class="d-flex justify-center my-10">
    <p class="text-h4">Reset your password</p>
  </div>
  <v-row justify="center">
     <v-col md="auto">
       <v-alert :type="typeAlert" v-if="meesageAlert  != ''">{{ meesageAlert }}</v-alert>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate" :disabled="formDisabled">
          <div class="mb-3">
            <p>Enter your email to send you an email with a password reset link</p>
            <v-text-field label="Email" outlined v-model.trim="email" :rules="emailRules" type="email" autocomplete="off" required></v-text-field>
            <v-btn class="mr-4" type="submit" :disabled="!valid">
              submit
            </v-btn>
          </div>    
        </v-form>
      </v-col>
  </v-row>
</v-container>
</template>
<script>
import firebase from "firebase"
//import { mapState } from 'vuex'

export default {

    data(){
      return {
        email: "",
        valid: true,
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        typeAlert: 'success',
        meesageAlert: '',
        formDisabled: false
      }
    },
    methods: {
      validate () {
        if (!this.$refs.form.validate()) return;
        this.formDisabled =true;
        firebase.auth().sendPasswordResetEmail(this.email)
          .then(res => {
            
            this.typeAlert = "success",
            this.meesageAlert = "Email has been sent";
            setTimeout(() => {
              this.meesageAlert = '';
              this.$router.push({name: 'Login'})
            }, 4000);
          })
          .catch(err => {
            console.log(err);
             this.formDisabled = false;
            this.typeAlert = "error",
            this.meesageAlert = "Error to send email";
            setTimeout(() => {
              this.meesageAlert = '';
            }, 2);
          })
      },
    },
}
</script>

<style scoped>
    .transition{
        transition: .5s;
    }
    .login-box{
        border: 1px solid #cacaca;
    }
    .login-box:hover{
        background-color: #e6e6e6 !important;
        transition: .3s;
        cursor: pointer;
    }
</style>
